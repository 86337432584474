import React, { useCallback, useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import styled from "styled-components";
import { getProvider } from "../../utils/onchain";
import { Box, ClickAwayListener, Typography } from "@mui/material";
import { TLog } from "../../types";
import { Button } from "../../components";
import { authApis } from "../../apis/auth";
import { auth_configs, getToken } from "../../utils/auth";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shortenAddress } from "../../utils/common";
import Logo from "../../assets/logo-with-text.png";
import XLogo from "../../assets/x-logo.jpeg";
import Background from "../../assets/background.png";
import WaitlistSuccess from "../../assets/waitlist-success.png";
import WaitlistFail from "../../assets/waitlist-fail.png";
import User from "../../assets/user.svg";

const LoginStatusToast = (status: string) => (
  <div>
    {status === "success" ? (
      <img
        src={WaitlistSuccess}
        alt="WaitlistSuccess"
        style={{
          borderRadius: "0.4rem",
          maxWidth: "400px",
        }}
      />
    ) : (
      <img
        src={WaitlistFail}
        alt="WaitlistFail"
        style={{
          borderRadius: "0.4rem",
          maxWidth: "400px",
        }}
      />
    )}
  </div>
);

const StyledWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${Background});
  background-size: contain;
  height: 100vh;
`;

const Login = () => {
  const loginToast = (status: string) => {
    toast(LoginStatusToast(status));
  };

  const [userInfo, setUserInfo] = useState<any>();

  const [refecthUserInfo, setRefecthUserInfo] = useState<boolean>(false);

  const [isUserMatchWithPublicKey, setIsUserMatchWithPublicKey] =
    useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const toggleWalletProps = open
    ? {
        opacity: 1,
        pointerEvents: "auto",
        transform: "translate3d(0,0,0) rotate3d(0,0,0,0)",
        boxShadow:
          "0 2.8px 2.2px rgba(0, 0, 0, 0.017), 0 6.7px 5.3px rgba(0, 0, 0, 0.024), 0 12.5px 10px rgba(0, 0, 0, 0.03), 0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.043), 0 100px 80px rgba(0, 0, 0, 0.06)",
      }
    : {};

  const provider = getProvider();

  const [logs, setLogs] = useState<TLog[]>([]);

  const createLog = useCallback(
    (log: TLog) => {
      return setLogs((logs) => [...logs, log]);
    },
    [setLogs]
  );

  useEffect(() => {
    if (!provider) return;

    provider.on("connect", (publicKey: PublicKey) => {
      createLog({
        status: "success",
        method: "connect",
        message: `Connected to account ${publicKey.toBase58()}`,
      });
    });

    provider.on("disconnect", () => {
      createLog({
        status: "warning",
        method: "disconnect",
        message: "👋",
      });
    });

    provider.on("accountChanged", (publicKey: PublicKey | null) => {
      if (publicKey) {
        createLog({
          status: "info",
          method: "accountChanged",
          message: `Switched to account ${publicKey.toBase58()}`,
        });
      } else {
        createLog({
          status: "info",
          method: "accountChanged",
          message: "Attempting to switch accounts.",
        });

        provider.connect().catch((error) => {
          createLog({
            status: "error",
            method: "accountChanged",
            message: `Failed to re-connect: ${error.message}`,
          });
        });
      }
    });

    return () => {
      provider.disconnect();
    };
  }, [provider]);

  const publicKey = provider?.publicKey;

  const handleConnect = async () => {
    const hasPhantomWallet = "phantom" in window;
    if (hasPhantomWallet) {
      if (!provider) return;

      try {
        const connect = await provider.connect();

        if (connect) {
          if (!isUserMatchWithPublicKey) {
            //email is publickey
            await handleLogin({ email: `${provider?.publicKey}` });
            // await handleLogin({
            //   email: "9gNrvvZ9RuTyRWooiEEypwcXU6kyXW8yWuhXU8tWUH5L",
            // });
          }
        }
      } catch (error) {
        console.log({
          status: "error",
          method: "connect",
          message: error.message,
        });
      }
    } else {
      window.open("https://phantom.app/", "_blank");
    }
  };

  /** Disconnect */
  const handleDisconnect = async () => {
    if (!provider) return;

    try {
      await provider.disconnect();
      localStorage.removeItem(auth_configs.storageTokenKeyName);
      setUserInfo({});
      setIsUserMatchWithPublicKey(false);
    } catch (error) {
      console.log({
        status: "error",
        method: "disconnect",
        message: error.message,
      });
    }
  };

  // const handleSignMessage = async () => {
  //   if (!provider) return;

  //   try {
  //     const message = `${provider?.publicKey}`;

  //     const signedMessage = await signMessage(provider, message);

  //     console.log({
  //       status: "success",
  //       method: "signMessage",
  //       message: `Message signed: ${JSON.stringify(signedMessage)}`,
  //     });

  //     //login
  //     if (signedMessage) {
  //       await login(signedMessage);
  //     }

  //     return signedMessage;
  //   } catch (error) {
  //     console.log({
  //       status: "error",
  //       method: "signMessage",
  //       message: error.message,
  //     });
  //   }
  // };

  const handleLogin = async (payload: any) => {
    try {
      const result = await authApis.login(payload);

      if (result) {
        localStorage.setItem(
          auth_configs.storageTokenKeyName,
          result.data.token
        );
        setRefecthUserInfo(!refecthUserInfo);
        loginToast("success");
      }
    } catch (error) {
      console.log({
        status: "error",
        method: "login",
        message: error?.response?.data?.message,
      });
      setRefecthUserInfo(!refecthUserInfo);
      localStorage.removeItem(auth_configs.storageTokenKeyName);
      loginToast("failed");
    }
  };

  const handleRedirectToX = () => {
    window.open("https://x.com/LeftCurve_Club", "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();

        const response = await authApis.user_info({ token: token });

        if (response) {
          setUserInfo(response.data);
        }
      } catch (error) {
        setUserInfo({});
        console.log("error", error);
      }
    };

    fetchData();
  }, [refecthUserInfo]);

  useEffect(() => {
    if (userInfo && publicKey) {
      setIsUserMatchWithPublicKey(
        userInfo?.publicKey?.toLowerCase() === `${publicKey}`.toLowerCase()
      );
    }
  }, [userInfo, publicKey]);

  return (
    <>
      <StyledWrapper>
        {/* header */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: { xs: "20px 10px", md: "40px" },
              img: { height: { xs: "35px", md: "50px" } },
            }}
          >
            <img src={Logo} alt="logo" />

            <Box>
              {isUserMatchWithPublicKey && publicKey ? (
                <>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpen(false);
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      position="relative"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <Typography
                          sx={{
                            color: "#45546b",
                            fontSize: "16px",
                          }}
                        >
                          {userInfo?.displayName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "relative",
                          marginLeft: "15px",
                          display: "inline-block",
                          width: "50px",
                          height: "50px",
                          textAlign: "center",
                          borderRadius: "100px",
                          transition: "box-shadow 0.3s ease",
                          background: `#f5f7ff center / 30px no-repeat url("${User}")`,
                          cursor: "pointer",
                          boxShadow: open
                            ? "0 2.8px 2.2px rgba(0, 0, 0, 0.017), 0 6.7px 5.3px rgba(0, 0, 0, 0.024), 0 12.5px 10px rgba(0, 0, 0, 0.03), 0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.043), 0 100px 80px rgba(0, 0, 0, 0.06)"
                            : "0 2.8px 2.2px rgba (174, 196, 215, 0.034), 0 6.7px 5.3px rgba(174, 196, 215, 0.048), 0 12.5px 10px rgba(174, 196, 215, 0.06), 0 22.3px 17.9px rgba(174, 196, 215, 0.072), 0 41.8px 33.4px rgba(174, 196, 215, 0.086), 0 100px 80px rgba(174, 196, 215, 0.12)",
                          "&:hover": {
                            boxShadow:
                              "0 2.8px 2.2px rgba(0, 0, 0, 0.017), 0 6.7px 5.3px rgba(0, 0, 0, 0.024), 0 12.5px 10px rgba(0, 0, 0, 0.03), 0 22.3px 17.9px rgba(0, 0, 0, 0.036), 0 41.8px 33.4px rgba(0, 0, 0, 0.043), 0 100px 80px rgba(0, 0, 0, 0.06)",
                          },
                        }}
                      />

                      <Box
                        sx={{
                          position: "absolute",
                          padding: "25px 35px",
                          textAlign: "center",
                          borderRadius: "10px",
                          backgroundColor: "#f5f7ff",
                          right: 0,
                          top: "calc(100% + 10px)",
                          zIndex: 999,
                          minWidth: "201px",
                          opacity: 0,
                          transform:
                            "translate3d(0,-10px,0) rotate3d(1,1,0,25deg)",
                          transition: "opacity 0.3s, transform 0.3s",
                          pointerEvents: "none",
                          ...toggleWalletProps,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#45546b",
                            fontSize: "16px",
                            cursor: "pointer",
                            marginBottom: "10px",
                            "&:hover": {
                              textDecoration: "underline",
                              transition: "0.2s all",
                            },
                          }}
                        >
                          {shortenAddress(userInfo?.publicKey, 5)}
                        </Typography>
                        <Button onClick={handleDisconnect}>Logout</Button>
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </>
              ) : (
                <>
                  <Button onClick={handleConnect}>Connect Wallet</Button>
                </>
              )}
            </Box>
          </Box>

          {/* footer */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: { xs: "20px 10px", md: "40px" },
              img: { height: { xs: "35px", md: "50px" } },
            }}
          >
            <img
              src={XLogo}
              alt="XLogo"
              style={{
                borderRadius: "10px",
                cursor: "pointer",
                height: "42px",
              }}
              onClick={handleRedirectToX}
            />
          </Box>
        </Box>

        <ToastContainer
          position="top-center"
          closeButton={false}
          theme="colored"
          transition={Slide}
          style={{ width: "440px" }}
        />
      </StyledWrapper>
    </>
  );
};

export default Login;
