import styled from "styled-components";

const Button = styled.button`
  background-color: #131324;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  font-family: "Impact", "Arial Black", sans-serif;
  letter-spacing: 0.3px;
  &:hover {
    background-color: #21213e;
  }
`;

export default Button;
