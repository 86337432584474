import axiosClient from "./axios-client";

export const authApis = {
  login(data: any): Promise<any> {
    return axiosClient.post("api/user/login", data);
  },

  user_info(token: any): Promise<any> {
    return axiosClient.post("api/user/resolveToken", token);
  },
};
