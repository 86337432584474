export const auth_configs = {
  storageTokenKeyName: "lc_access_token",
  onTokenExpiration: "lc_refresh_token",
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem(auth_configs.storageTokenKeyName);
    return token || null;
  }
};

export const useAuth = () => {
  return Boolean(getToken());
};
